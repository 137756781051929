.layout-cruise-lines-itineraries {

    table, th, td {

        border-collapse: collapse;
    }

    th, td {
        padding: 15px;
        border: 1px solid $staticGreen;
        vertical-align: top;
    }


    table tr td:first-child {
        border-left:1px solid $staticGreen;
    }

    table {
        
        font-family: $font-name;
        max-width: 1300px;
        margin: 0 auto;
        width: 100%;
        table-layout: fixed;

        thead {
            
            tr {
                height: auto;
                background: $darkBlue;
            }

            th {
                // width: 15%;
                color: $white;
                font-size: $Body-Size;
                line-height: 1.5rem;
                letter-spacing: $Body-Char-Spacing;
                text-transform: uppercase;
                vertical-align: middle;
                border-right: 1px solid white;
                // padding: 1rem;

                &:last-of-type {
                    border-right: 1px solid $darkBlue;
                }
            }
        }    

        td {

            figure {
                a {
                    background: none;

                    &:hover {
                        background:none;
                    }
                }
            }
            img {
                width: 100%;
                // max-width: 150px;
                height: 100%;
                margin: 0 auto;
                vertical-align: middle;
            }
            p {
                font-size: $Body-Size-Small;
                line-height: 1.25rem;
                letter-spacing: $Body-Char-Spacing;
                // margin: 1rem;
            }
            ol {
                list-style: decimal;
                // margin: 1rem;
                li {
                    font-size: $Body-Size-Small;
                    line-height: 1.25rem;
                    letter-spacing: $Body-Char-Spacing;
                    margin-left: 20px;
                    margin-bottom: .4rem
                }
            }

            ul {
                list-style: circle;
                margin: 1rem;
                li {
                    font-size: $Body-Size-Small;
                    line-height: 1.25rem;
                    letter-spacing: $Body-Char-Spacing;
                    margin-left: 20px;
                    margin-bottom: .4rem
                }
            }
    
        }
    }

}




