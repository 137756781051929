.sitewide-notification {
    width: 100%;
    background: #0068ab;
    height: 50px;
    text-align: center;
    top: 0;
    display: none;
    position: fixed;
    // z-index: 10000000;
    z-index: 100;
    font-family: Montserrat,sans-serif;

    span#closeNotify {
        /* float: right; */
        position: absolute;
        right: 20px;
        cursor: pointer;
        color: white;

        @include breakpoint(tablet) {
            right: 20px;
            top: 15px;
        }
        @include breakpoint(mobileonly) {
            right: 10px;
            top: 4px;
        }
    }


    &-wrapper {
        display: table-cell;
        justify-content: center;
        /* padding: 25px; */
        /* height: 100%; */
        /* width: 100%; */
        height: 50px;
        max-height: 50px;
        min-height: 50px;
        margin: 0 auto;
        vertical-align: middle;

        @include breakpoint(tablet) {
            padding: 0 6.5px 0 6.5px;
        }
        @include breakpoint(mobile) {
            padding: 0 6.5px 0 6.5px;
        }
    }

    p {

        line-height: 1.875rem;
        font-size: 0.9375rem;
        letter-spacing: 0.125rem;
        color: #fff;
        font-weight: 700;
        display: inline-block;
        margin: 0 auto;
        text-transform: uppercase;
        @include breakpoint(tablet) {
           width: 90%;
           line-height: 1.25rem;
        }
        @include breakpoint(mobileonly) {
            width: 90%;
            line-height: 1.25rem;
         }
    }


    a {
        font-size: $Menu-Nav-Size;
        letter-spacing: $char-spacing-3;
        color: #fff;
        font-weight: 700;
        display: inline;
        margin: 0 auto;

        button {
            color: #fff!important;
            text-transform: uppercase;
            background: #C40000;
            // padding: 5px 15px 5px 15px;
            line-height: 1.875rem;
            font-size: 0.9375rem;
            letter-spacing: 0.125rem;
            cursor: pointer;
            font-family: $font-name, sans-serif;
            font-weight: 600;
            transition: $hoverGreen-transition;

                @include breakpoint(tablet) {
                    line-height: 1.25rem;
                }    

                // &:after {
                //     content: " ";
                //     background-image: url('../svg/chevron_white.svg');
                //     background-size: cover;
                //     position: relative;
                //     display: inline-block;
                //     top: 3px;
                //     margin-left: 29px;
                //     width: 12px;
                //     height: 18px;

                // }

                &:hover {
                    background: #e52328;
                }
        }
    }
}

.showTable {
    display: table;
}