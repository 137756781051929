.footer {
  order: 4;
  height: auto;
  margin-top: 4rem;
  padding: 3rem 0 2rem 0;
  background: $footerGrey;
  // margin-top: 5rem;

  &--address {
    display: block;
    // padding: 1rem 0rem 0 0;
    padding: 0rem 0 0;
    text-align: center;
    text-transform: unset;

    @include breakpoint(tablet) {
      width: 85%;
      margin: 0 auto;
    }

    @include breakpoint(mobileonly) {
      width: 85%;
      margin: 0 auto;
    }
  }

  nav {
    width: 100%;
    text-align: center;

    .footer__social-links {
      display: inline;
    }

    .footer-container.desktop {
      display: inline;
    }

    .nav--link-foot {
      display: inline-block;
      padding: 0em 0.75rem 0.75rem 0.75rem;
      text-decoration: none;
      letter-spacing: 0.15625rem;
      text-transform: unset;
      color: #fff;
      font-family: $font-name, sans-serif;
      font-size: 1rem;
      font-weight: 500; // padding: .5rem .75rem;
      line-height: 1.875rem;

      @include breakpoint(tablet) {
        width: auto;
        padding: 0 1.5rem 1rem;
      }

      // padding: 0 .75rem;
      @include breakpoint(mobileonly) {
        width: auto;
        padding: 0 1.5rem 1rem;
      }

      button {
        padding: 5px 15px;
        cursor: pointer;
        transition: $hoverGreen-transition;
        letter-spacing: 0.125rem;
        color: #fff !important;
        border: 0;
        // text-transform: uppercase;
        background: #c40000;
        font-family: Montserrat, sans-serif;
        font-size: 0.9375rem;
        font-weight: 600;
        line-height: 1.875rem;

        &:hover {
          background: #e52328;
        }
      }
    }
  }
  @media (min-width: 40rem) {
    grid-column: span 8;
  }
}

// @media screen and (max-width: 768px) {
//     .parent {
//       display: table;
//     }
//     .child1 {
//       display: table-footer-group;
//     }
//     .child3 {
//       display: table-header-group;
//     }
//   }
a.desktop.nav--link-foot {
  &:hover {
    color: #e52328;
  }
}

.footer-container {
  a.nav--link-foot {
    button {
      width: 100%;
      padding: 5px 15px;
      cursor: pointer;
      transition: $hoverGreen-transition;
      letter-spacing: 0.125rem;

      color: #fff !important;
      border: 0;
      // text-transform: uppercase;
      background: #c40000;
      font-family: Montserrat, sans-serif;
      font-size: 0.9375rem;
      font-weight: 600;
      line-height: 1.875rem;

      @include breakpoint(tablet) {
        display: block;
        width: 85%;
        margin: 0 auto;

        // display: block;
      }

      @include breakpoint(mobileonly) {
        display: block;
        width: 85%;
        margin: 0 auto;

        // display: block;
      }

      &:hover {
        background: #e52328;
      }
    }
  }
}

.nav--link-foot {
  padding: 0em 0.75rem 0.75rem 0.75rem;
  text-decoration: none;
  letter-spacing: 0.15625rem;
  text-transform: unset;
  color: #fff;
  font-family: $font-name, sans-serif;
  // display: inline-block;
  font-size: 1rem;
  font-weight: 500; // padding: .5rem .75rem;
  line-height: 1.875rem;

  @include breakpoint(tablet) {
    width: auto;
    padding: 0 1.5rem 1rem;
  }

  // padding: 0 .75rem;
  @include breakpoint(mobileonly) {
    width: auto;
    padding: 0 1.5rem 1rem;
  }

  button {
    padding: 5px 15px;
    cursor: pointer;
    transition: $hoverGreen-transition;
    letter-spacing: 0.125rem;
    color: #fff !important;
    border: 0;
    border: 0;
    // text-transform: uppercase;
    background: #c40000;
    font-family: Montserrat, sans-serif;
    font-size: 0.9375rem;
    font-weight: 600;
    line-height: 1.875rem;

    &:hover {
      background: #e52328;
    }

    .footer--address {
      display: block;
    }
  }
}
