article.home__featured-article {

    &-wrapper {
        display: grid;
        grid-template-columns: 50% 50%;

        @include breakpoint(tablet) {
            grid-template-columns: 100%;
         }
 

        @include breakpoint(mobileonly) {
           grid-template-columns: 100%;
        }


        &:nth-of-type(2) .home__featured-article-image:nth-child(1) {
            order: 2;

            @include breakpoint(tablet) {
                order: unset;
             }

            @include breakpoint(mobileonly) {
                order: unset;
             }
        }
    }
}

.home__featured-article {

    &-image {
        
    
        img {

    
            min-height: 500px;
            object-fit: cover;
            object-position: center center;
            vertical-align: bottom;
            height: 500px;
            width: 100%;

            @include breakpoint(tablet) {
                min-height: 300px;
                height: 300px;
                padding-top: 1rem;
                padding-bottom: 1rem;
                
             }

            @include breakpoint(mobileonly) {
                min-height: 300px;
                height: 300px;
                padding-top: 1rem;
                padding-bottom: 1rem;
                
             }
        }

    }

    &-text {
        display: table;
        height: 100%;
    }
    &-content {
        font-family: $font-name,sans-serif;
        color: $copyGrey;
        vertical-align: middle;
        display: table-cell;
        padding: 0 2.5rem 0 2.5rem;

        @include breakpoint(tablet) {
            padding: 1rem 2.5rem 1rem 2.5rem;
            
         }

        @include breakpoint(mobileonly) {
            padding: 1rem 2.5rem 1rem 2.5rem;
            
         }

        p {
            font-size: 1rem;
            line-height: 1.875rem;
            letter-spacing: 0.09375rem;
            padding-bottom: 1rem;

            &:empty{
                display: none;
            }
        }

        h2 {
            font-size: 2rem;
            line-height: 2.5rem;
            letter-spacing: .15rem;
        }

        h4.date {
            font-size: 1rem;
            letter-spacing: 0.15625rem;
            line-height: 1.875rem;
            font-weight: 300;
            font-style: italic;
            padding: 0rem 0rem 1rem 0rem;
        }

        button {
            color: #fff!important;
            text-transform: uppercase;
            background: #C40000;
            border: 0;
            padding: 5px 15px 5px 15px;
            margin-top: 1rem;
            line-height: 1.875rem;
            cursor: pointer;
            font-size: 0.9375rem;
            letter-spacing: 0.125rem;
            font-family: $font-name, sans-serif;
            font-weight: 600;
            transition: $hoverGreen-transition;
            
                &:after {
                    content: " ";
                    background-image: url('../svg/chevron_white.svg');
                    background-size: cover;
                    position: relative;
                    display: inline-block;
                    top: 3px;
                    margin-left: 29px;
                    width: 12px;
                    height: 18px;
                }

                &:hover {
                    background: #e52328;
                }    
            }
        }
    }    

    //IE11
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { 
    .home__featured-article-wrapper {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .home__featured-article-wrapper:after{
        content:'';
        flex-grow: 1;
      }
    .home__featured-article-image  {
       width: 50%;
    }

    .home__featured-article-image img  {
        width: 100%;
        height: auto;
        background-size: cover;
        background-position-x: center;
        min-height: none;
     }

    .home__featured-article-text {
        padding: 2rem;
        width: 50%;
    }
}