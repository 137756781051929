@media print {
    @page {
        size: 330mm 427mm;
        margin: 10mm;
      }
      main {
        width: 1150px;
      }
   table {
       width: 95%;
       table-layout: auto;
       border-collapse: separate;
       font-size: 8pt !important;
       

   }
   .header--inner {
       display: none;
   }
   a.sf-back-to-top button {
       display: none;
   }
   .footer-container a.nav--link-foot button {
       display: none;
   }
   table td figure {
       width: 100px;
   }
   table th {
       color: $darkBlue !important;
       z-index: 100000;
       font-size: 8pt !important;
       border-right: 1px solid $staticGreen !important;
   }
   table img {
       width: 100px;
   }

   table td {
    font-size: 8pt !important;
   }
   * {
    margin:0;
    padding:0;
    font-family: sans-serif;
}
    .sitewide-notification.showTable {
        display: none;
    }

    /* Defining all page breaks */
    a {
        page-break-inside:avoid
    }
    blockquote {
        page-break-inside: avoid;
    }
    h1, h2, h3, h4, h5, h6 { page-break-after:avoid; 
        page-break-inside:avoid }
    img { page-break-inside:avoid; 
        page-break-after:avoid; }
    table, pre { page-break-inside:avoid }
    tr    { page-break-inside:avoid; page-break-after:auto }
    ul, ol, dl  { page-break-before:avoid }

    h1 {
        font-size: 25pt;
    }
    h2 {
        font-size: 14pt;
    }

    /* Displaying link color and link behaviour */
    a:link, a:visited, a {
        background: transparent;
        color: #520;
        font-weight: bold;
        text-decoration: underline;
        text-align: left;
    }
    
    a {
        page-break-inside:avoid
    }
    
    .entry iframe, ins {
        display: none;
        width: 0 !important;
        height: 0 !important;
        overflow: hidden !important;
        line-height: 0pt !important;
        white-space: nowrap;
    }
    .embed-youtube, .embed-responsive {
        position: absolute;
        height: 0;
        overflow: hidden;
    }

  
}