html {
    box-sizing: border-box;
    overflow-x: hidden;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

::selection {
    background: $color-primary;
}

.hidden {
    display: none;
}

