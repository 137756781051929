.block__executive {

    &-wrapper {
        display:grid;
        grid-template-columns: 45% 45%;
        grid-gap: 2rem;
        padding-top: 4rem;
        padding-bottom: 2rem;
        justify-content: center;

        @include breakpoint(tablet) {
            grid-template-columns: 100%;
            // padding: 0;
            padding-bottom: 2rem;
            
        }

        @include breakpoint(mobileonly) {
            grid-template-columns: 100%;
            // padding: 0;
            padding-bottom: 2rem;
            
        }
    }

    &-head-shot {
        img {
            max-height: 400px;
            min-height: 400px;
            height: 400px;
            object-fit: contain;
            width: 100%;

            @include breakpoint(tablet) {
                max-height: 400px;
                /* min-height: 400px; */
                height: auto;
                -o-object-fit: contain;
                object-fit: contain;
                width: 85%;
                display: block;
                margin: 0 auto;
            }

            @include breakpoint(mobileonly) {
                max-height: 400px;
                /* min-height: 400px; */
                height: auto;
                -o-object-fit: contain;
                object-fit: contain;
                width: 85%;
                display: block;
                margin: 0 auto;
            }
           

        }
    }

    &-content {
        font-family: $font-name, sans-serif;
        


        @include breakpoint(tablet) {
            max-height: 400px;
            // width: 85%;
            margin: 0 auto;
            padding-top: 2rem;
            width: auto;
            // margin: 0 auto;
            
        }

        @include breakpoint(mobileonly) {
            max-height: 400px;
            width: 85%;
            margin: 0 auto;
            padding-top: 2rem;
            
        }

        h2 {
            color: $copyGrey;
            font-size: 1.75rem;
            letter-spacing: 0.125rem;
            line-height: 2.1875rem;
            text-transform: uppercase;
            margin: 0;
            padding-bottom: .25rem;


            @include breakpoint(tablet) {
                font-size: 1.5rem;
                letter-spacing: .125rem;
                line-height: 2rem;
    
            }
            @include breakpoint(mobileonly) {
                font-size: 1.5rem;
                letter-spacing: .125rem;
                line-height: 2rem;
    
            }


        }
        h3 {
            color: $copyGrey;
            font-size: 1rem;
            line-height: 1.875rem;
            letter-spacing: 0.09375rem;
            padding-bottom: .25rem;
            @include breakpoint(tablet) {
                font-size: 1rem;
                line-height: 18px;
                line-height: 1.75rem;
            }

            @include breakpoint(mobileonly) {
                font-size: 1rem;
                line-height: 18px;
                line-height: 1.75rem;
            }

        }

        a {
            font-size: 0.875rem;
            line-height: 1.75rem; 
            letter-spacing: 0.15625rem;
            color: #C40000;
            display: block;
            padding-bottom: .25rem;


            @include breakpoint(tablet) {
                font-size: 1rem;
                line-height: 18px;
                line-height: 1.75rem;
            }

            @include breakpoint(mobileonly) {
                font-size: 1rem;
                line-height: 18px;
                line-height: 1.75rem;
            }

        }

        .bio {
            font-size: 0.875rem;
            line-height: 1.75rem;
            color: #C40000;
            text-transform: uppercase;
            letter-spacing: 0.15625rem;
            cursor: pointer;

            img {
                height: 15px;
                padding-bottom: 4px;
            }
        }

    }
}

//IE11
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { 

    .block__executive-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .block__executive-wrapper:after{
        content:'';
        flex-grow: 1;
      }

    .block__executive-headshot {
        width: calc(45%);
        margin: 0 2%;
    }
    .block__executive-content {
        width: calc(45%);
        margin: 0 2%;
    }
}    
@supports (-ms-ime-align:auto) {
 
}    
