//******************************************
// COLORS
//******************************************

//Greens
$staticGreen: #6CB23D;
$hoverGreen: #85C45B;

//Grays
$footerGrey: #464646;
$menuBlack: #272727;
$copyGrey: #626262;
$white : #FFF;

//Blues
$darkBlue: #0068AB;
$mediumBlue: #75C0F3;
$lightBlue: #BEE2F7;