.block__two-up {

    &-wrapper {
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: 1fr;
        height: 450px;

        @include breakpoint(tablet) {
            grid-template-columns: 100%;
            grid-template-rows: 1fr;
            height: auto;
        }

        @include breakpoint(mobileonly) {
            grid-template-columns: 100%;
            grid-template-rows: 1fr;
            height: auto;
        }

        .embed-container {
            height: 450px;

            @include breakpoint(tablet) {
                height: auto;
            }

            @include breakpoint(mobileonly) {
                height: auto;
            }
        }
    }

    &-text {
        font-family: $font-name,sans-serif;
        color: $copyGrey;
        vertical-align: middle;
        display: table-cell;
        padding: 0 2.5rem 0 2.5rem;

        @include breakpoint(tablet) {
            padding: 1rem 0;
            display: block;
            width: 90%;
            margin: 0 auto;
        
        }

        @include breakpoint(mobileonly) {
            padding: 1rem 0;
            display: block;
            width: 90%;
            margin: 0 auto;
        
        }
      
        p {
            font-size: 1rem;
            line-height: 1.875rem;
            letter-spacing: 0.09375rem;
            padding-bottom: 1rem;

            &:empty{
                display: none;
            }
            @include breakpoint(tablet) {
                font-size: 16px;
                line-height: 25px;
                margin-bottom: 1rem;
                letter-spacing: 1.25px;
              }
            @include breakpoint(mobileonly) {
                font-size: 16px;
                line-height: 25px;
                margin-bottom: 1rem;
                letter-spacing: 1.25px;
              }
        }

        h2 {
            font-size: 2.5rem;
            line-height: 3.125rem;
            // padding-bottom: 1rem;
            letter-spacing: 0.1875rem;
            // padding-top: 1.5rem;
        }

        button {
            color: #fff!important;
            text-transform: uppercase;
            background: #C40000;
            border: 0;
            padding: 5px 15px 5px 15px;
            line-height: 1.875rem;
            cursor: pointer;
            font-size: 0.9375rem;
            letter-spacing: 0.125rem;
            font-family: $font-name, sans-serif;
            font-weight: 600;
            transition: $hoverGreen-transition;

                &:after {
                    content: " ";
                    background-image: url('../svg/chevron_white.svg');
                    background-size: cover;
                    position: relative;
                    display: inline-block;
                    top: 3px;
                    margin-left: 29px;
                    width: 12px;
                    height: 18px;

                }

                &:hover {
                    background: #e52328;
                }
        }

    }

    &-image img {
        min-height: 450px;
        object-fit: cover;
        object-position: center center;
        max-height: 450px;
        width: 100%;


    }

    &-video {
        iframe {
            min-height: 450px;
        }
    }

}

.right { 
    display: table; 
    height: 100%; 

    &:nth-of-type(1) {
        order: 2;
    }
}

.left {
    display: table; 
    height: 100%; 
}

.other {
    display: table; 
    height: 100%; 
}

//IE11
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { 
    .block__two-up-wrapper   {
       display: flex;
    }
    .block__two-up-wrapper .left   {
        width: 45%;
    }

    .block__two-up-wrapper .right   {
        // float: left;
        width: 45%;
    }
    .block__two-up-wrapper .other   {
        // float: left;
        width: 45%;
    }
    .block__two-up-image {
        height: 450px;
        min-height: 450px;
        max-height: 450px;
        width: 100%;
        overflow-x: hidden;
        overflow-y: hidden;
    }
    .block__two-up-image img {
        width: 100%;
        height: auto;
        max-height: none;
        min-height: none;
    }
}    