.content__section-header-section {

    .block__text-with-images {

        &-container {
            width: 60%;


            @include breakpoint(tablet) {
             
                width: 85%;

            }
            @include breakpoint(mobileonly) {
             
                width: 85%;

            }

        }

    }

      

}