

.card {
    width: 200px;
    height: auto;
    position: relative;
    display: block;
    margin: 0 auto;

}
.card img {
    display:block;
    margin: 0 auto;
}
.card .img-top {
    display: none;
    margin: 0 auto;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
}

.card:hover .img-top {
    display: block;
    margin: 0 auto;
    z-index: auto;

    @include breakpoint(tablet) {
        display: none;
      }

    @include breakpoint(mobileonly) {
         display: none;
      }


}

.block__three-up {

    &-wrapper {

        display: grid;
        grid-gap: 1%;
        grid-template-columns: 30% 30% 30%;
        -ms-flex-pack: distribute;
        justify-content: center;
        // padding-top: 3rem;
        // padding-bottom: 3rem;

        @include breakpoint(tablet) {
            grid-template-columns: 100%;
          }

        @include breakpoint(mobileonly) {
            grid-template-columns: 100%;
          }
    }

    &-item {
        padding: 1rem;
        font-family: $font-name, sans-serif;
        color: $copyGrey;
        display: grid;
        grid-template-rows: 1fr;

        img {
            // margin: 0 auto;
            // display: block;
            // max-width: unset;
            // padding-top: 1.875rem;

        }

        h3 {
            text-align: center;
            font-size: 1.75rem;
            letter-spacing: 0.125rem;
            line-height: 2.1875rem;
            padding: 1rem 0;
            margin: 0 auto;
            width: 80%;
        }
        p {
            width: 80%;
            margin: 0 auto;
            // font-size: 1rem;
            // line-height: 1.875rem;
            // max-height: 75px;
            // min-height: 75px;
            font-size: 0.875rem;
            line-height: 1.25rem;
            // margin-bottom: 1.875rem;
            letter-spacing: 0.09375rem;
        }

        button {
            margin: 2.5rem auto;
            display: block;
            color: #fff!important;
            border: 0;
            text-transform: uppercase;
            cursor: pointer;
            background: #C40000;
            padding: 5px 15px 5px 15px;
            line-height: 1.875rem;
            font-size: 0.9375rem;
            letter-spacing: 0.125rem;
            font-family: $font-name, sans-serif;
            font-weight: 600;
            width: 80%;
            transition: $hoverGreen-transition;

                &:after {
                    content: " ";
                    background-image: url('../svg/chevron_white.svg');
                    background-size: cover;
                    position: relative;
                    display: inline-block;
                    top: 3px;
                    margin-left: 29px;
                    width: 12px;
                    height: 18px;

                }

                &:hover {
                    background: #e52328;
                }
        }
    }

}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { 
    .block__three-up-wrapper  {
        display: flex;
        justify-content: space-between;
    }
    .block__three-up-wrapper:after{
        content:'';
        flex-grow: 1;
      }

      .block__three-up-item {
          width: 30%;
      }
}