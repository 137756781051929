.block__rate-calculator {


    &-wrapper {
        width: 60%;
        max-width: 1300px;
        margin: 0 auto;
        padding-top: 3rem;
        
        h3 {
            font-size: $Subhead-Size;
            font-family: $font-name;
            letter-spacing: $char-spacing-2;
            line-height: $Subhead-LH;
            color: $copyGrey;
            text-transform: uppercase;
            padding: 2rem;
            text-align: center;
            width: 60%;
            margin: 0 auto;

                @include breakpoint(mobileonly) {
                  width: 90%;
      
                }

        }

        hr {
            width: 85%;
            margin: 0 auto;
            border: 1px solid #626262;
            margin-bottom: 2rem;

            @include breakpoint(tablet) {
                width: 90%;
              }
      
            }

        @include breakpoint(tablet) {
          width: 90%;
        }

    }

    &-flex {
        width: 60%;
        margin: 0 auto;
        padding-bottom: 3rem;

        @include breakpoint(tablet) {
            width: 80%;
          }

        @include breakpoint(mobileonly) {
              width: 90%;
        }

          form {

            width: 100%;

            .form-flex {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                position: relative;
            }

           .block__rate-calculator-form-input {

                width: 35%;
               
                label {
                    display: block;
                    padding-bottom: .5rem;
                    color: #c40000;
                    font-weight: 500;
                    
                }

                input {
                    background-color: #fff;
                    border: 0;
                    outline: 2px solid #c40000;
                    border-radius: 0;
                    height: 3em;
                    padding: 5px;
                    width: 100%;
                    color: #626262;
                    text-indent: 5px;
                    font-size: 1rem;
                    letter-spacing: .09375rem;
                    line-height: 1.875rem;
                }
            }

            button {
                display: block;
                margin: 2rem;
                margin: 2.5rem auto;
                color: #fff!important;
                text-transform: uppercase;
                background: #c40000;
                padding: 5px 35px;
                cursor: pointer;
                outline: 2px solid #c40000;
                line-height: 1.875rem;
                font-size: .9375rem;
                height: 4rem;
                border: 0;
                letter-spacing: .125rem;
                font-family: Montserrat,sans-serif;
                font-weight: 600;
                transition: background .3s ease;

                &:hover {
                    background: #e52328;
                    border: 0;
                }
            }

            div.currency-position {
                position: relative;
                width: 60%;
                border-bottom: 1px solid #979797;

                span.currency {
              
                    line-height: 3rem;
                    font-size: 2rem;
                    color: #626262;
                    position: absolute;
                    left: 0;
                    bottom: 0rem;
                    
                }
            }


            div#price {
                
                margin-left: 2rem;
                line-height: 3rem;
                font-size: 2rem;
                color: #626262;

          }

        }
       
    }
}

.block__rate-fa-calculator-disclaimer {
  font-family: Helvetica;
  font-size: 1rem;
  color: #626262;
  letter-spacing: 1px;
  line-height: 1.5rem;

    a {
        color: #626262;

        &:hover {
            color: #e52328;

        }
    }

    p {
            margin-bottom: 1rem;
    }

    ul {
        list-style-type: disc;
        columns: 2;
        list-style-position: inside;
        width: 80%;
        margin: 0 auto;

            @include breakpoint(mobileonly) {
                width: 100%;
                columns: 1;
            }

        li  {
            font-size: 1rem;
            color: #626262;
            letter-spacing: 1px;
            line-height: 1.3rem;
            margin-bottom: .5rem;
            padding-left: 1.28rem;
            text-indent: -1.38em;
       }
    }
}

input#weight:focus {
  outline-offset: 0;
}