$color-background: #fefefe !default;
$color-text: #333 !default;
$color-text-button: #333 !default;
$color-mark: #f3f315 !default;

$color-border: #aaa !default;
$color-table-row: lighten($color-text, 75%) !default;

$color-primary: #7fd4ff !default;
$color-secondary: #86deb7 !default;

$color-toggle-on: #86deb7 !default;
$color-toggle-off: #da6869 !default;

$color-disabled: #aaa !default;

// $font-url: 'https://fonts.googleapis.com/css?family=Droid+Sans:400,700|Droid+Sans+Mono' !default;
// $font-name: 'Droid Sans' !default;
// $font-name-mono: 'Droid Sans Mono' !default;

$font-name: 'Montserrat', sans-serif;

//semi bold
$semiBold: 600;

//regular
$regular: 400;

//light italic
$light: 300;
$italic: italic;

//Bold
$bold: 700;

//Medium
$medium: 500;

//Character Spacing
$Body-Char-Spacing: 0.09375rem;

$char-spacing-2: 0.125rem;

$char-spacing-2-5: 0.15625rem;

$char-spacing-3: 0.1875rem;

//Font-size

//Section Header
$SH-size: 3.125rem;
$SH-lh: 4.6875rem;

//Button

$Button-size: 0.9375rem;
$Button-lh: 1.25rem;

//Hover Green Background
$hoverGreen-transition: background .3s ease;

//Accordion All Caps
$Accordion-Cap-Size: 1.5625rem;
$Accordion-Cap-LH: 2rem;

//Pagination
$Pagination-Size: 1.25rem;
$Pagination-LH: 1.5625rem;

//Subhead
$Subhead-Size: 1.75rem;
$Subhead-LH: 2.1875rem;

//Headline
$Headline-Size: 2.5rem;
$Headline-LH: 3.125rem;

//Body
$Body-Size: 1rem;
$Body-LH: 1.875rem;

//Table Copy
$Body-Size-Small: 0.875rem;
$Body-LH-Small: 1.75rem;

//Menu Nav Headers
$Menu-Nav-Size: 1.5625rem;
$Menu-LH: 1.875rem;

//Statistic Num
$Stat-num-size: 10.3125rem;
$Stat-num-LH: 9.375rem;

//Statistic Text
$Stat-text-size: 3.125rem;
$Stat-text-LH: 4.6875rem;

//Statistic Subhead;
$Stat-sub-size: 1.5625rem;
$Stat-sub-LH: 2.1875rem;