// $site-breakpoints : (
//   mobile : max-width 767px,
//   tablet : min-width 768px max-width 1024px,
//   desktop : min-width 1025px max-width 1400px,
//   wide : min-width 1400px
// ) !default;

// @mixin breakpoint($which...) {
//   $breaks : '';
//   @each $breakpoint in $which {
//     @if map-has-key($site-breakpoints, $breakpoint) {
//       @if $breaks != '' {
//         $breaks : "#{$breaks}, ";
//       }
//       $breakpoint-terms : map-get($site-breakpoints, $breakpoint);
//       $list-len : length($breakpoint-terms);
//       $next-break : '';

//     @while($list-len > 1) {
//       @if $next-break != '' {
//         $next-break : "#{$next-break} and ";
//       }
//         $next-break : "#{$next-break} (#{nth($breakpoint-terms, $list-len - 1)} : #{nth($breakpoint-terms, $list-len)})";
//         $list-len : $list-len - 2;
//       }
//         $breaks : "#{$breaks} #{$next-break}";
//       }
//     }

//   @media #{$breaks} {
//     @content;
//   }
// }
/********************
BREAKPOINTS
*********************/

 @mixin breakpoint($point) {
   @if $point == desktop {
     @media (min-width: 1025px) { @content ; }
  }
   @else if $point == tablet {
     @media (max-width: 1024px) { @content ; }
  }
  //   @else if $point == tablet {
  //    @media (max-width: 768px) { @content ; }
  // }
  //  @else if $point == phablet {
  //    @media (min-width: 501px)  { @content ; }
  // }
  @else if $point == mobileonly {
     @media (max-width: 500px)  { @content ; }

  }
}

.mobile {
  @include breakpoint(desktop) {
    display: none !important;
  }

  
  @include breakpoint(laptop) {
    display: none !important;
  }
}


.desktop {
  @include breakpoint(mobileonly) {
    display: none !important;
  }
  @include breakpoint(tablet) {
    display: none !important;
  }


}

.laptop {
  @include breakpoint(mobileonly) {
    display: none !important;
  }
  @include breakpoint(tablet) {
    display: none !important;
  }


}


