.content__wrapper-available-properties {
    display: grid;
    grid-template-columns: 30% 68%;
    // grid-gap: .5rem;
    font-family: $font-name;

    @include breakpoint(tablet) {
        grid-template-columns: 100%;
      }

    @include breakpoint(mobileonly) {
        grid-template-columns: 100%;
      }

      table {
        table-layout: fixed;
      }


    table tr td {
        border-collapse: collapse;
        border-top: 0;
        border-left: 0;
    }

    td, th {
        padding: .25rem;
    }
    table.details {
        border-right: 2px solid $mediumBlue;
        margin: 3rem;
        color: $copyGrey;
        table-layout: fixed;
        height: 15rem;

        @include breakpoint(tablet) {
            margin: 0 auto;
            border: 0;
            width: 15rem;
            margin-top: 2rem;
            order: 2;
            margin-bottom: 2rem; 
          }

        @include breakpoint(mobileonly) {
            margin: 0 auto;
            border: 0;
            margin-top: 2rem;
            order: 2;
            margin-bottom: 2rem;
          }

        
        td {

            font-size: $Body-Size;
            line-height: $Body-LH;
            letter-spacing: 0.09375rem;
            
            img {
                vertical-align: text-top;
                margin-right: 5px;
                width: auto;
                height: auto;
            }

        }
    }
}

.block__available-properties {

    &-wrapper {
        padding-bottom: 2rem;
        padding-top: 3rem;
        width: 85%;
        font-family: $font-name;
        margin: 0 auto;

        @include breakpoint(tablet) {
            width: 90%;
           }

        @include breakpoint(mobileonly) {
           width: 90%;
          }


        h2 {
            font-size: $Headline-Size;
            line-height: $Headline-LH;
            color: $copyGrey;
            letter-spacing: $char-spacing-3;

        }

        h3 {
            font-size: $Subhead-Size;
            line-height: $Subhead-LH;
            color: $copyGrey;
            text-transform: uppercase;
            letter-spacing: $char-spacing-3;
            margin-bottom: 1.5rem;
        }

    }

    &-images-wrapper {
        // display: flex;
        // width: 100%;
        // flex-wrap: wrap;
        // justify-content: space-between;
        // flex-flow: row wrap;
        // max-width: 1300px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        // margin-left: 1%;
        width: 100%;
        max-width: 1300px;
        justify-content: space-between;
        margin: 0 auto;
        padding-top: 1rem;
        padding-bottom: 3rem;

        @include breakpoint(tablet) {
            display: block;
            height: auto;
           }
        @include breakpoint(mobileonly) {
            display: block;
            height: auto;
           }
       
    }

    &-image {
        line-height: 0;
        height: 100%;
        -webkit-box-flex: 1;
        -ms-flex: auto;
        flex: 1 1 45%;
        margin: 0.75rem;


        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            height: 500px;


            @include breakpoint(tablet) {
                display: block;
                height: 300px;
               }
            @include breakpoint(mobileonly) {
                display: block;
                height: 300px;
               }

        }
    }
}

.content__wrapper-available-properties-aggregate {
    grid-template-columns: 100%;
}

//IE11
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { 
    .content__wrapper-available-properties {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .content__wrapper-available-properties table.details {
        width:30%;
    }
    .block__available-properties-wrapper {
        width: 60%;
    }
    // .block__recent-news-wrapper :after{
    //     content:'';
    //     flex-grow: 1;
    //   }

    //   .block__recent-news-item  {
    //       width: 30%;
    //   }
}