.search__results {
    
    &-wrapper {
        font-family: $font-name;
        margin: 0 auto;
        max-width: 1300px;
        // width: 85%;

        ul {
            // display: grid;
            // grid-template-columns: 46% 46%;
            // grid-gap: 8%;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            width: 70%;
            margin: 0 auto;
            justify-content: center;


            li {
                margin-bottom: 1.5rem;

                a {
                    color: #626262;
                    transition: color .3s ease;
                    font-size: $Accordion-Cap-Size;
                    line-height: $Accordion-Cap-LH;
                    letter-spacing: $char-spacing-2;

                    &:hover {
                        color: #e52328;
                    }
                    
                }
            }
        }

    }
}    

h2.search__results-header {
    padding: 0;
    font-weight: 600
}