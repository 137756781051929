.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}



.full-width-image {
    
    img {
        margin: 0 auto;
        display: block;
        max-width: 100%;
        width: 100%;
        object-fit: cover;
        height: auto;
        
    }
}