#menuToggle
{
  display: block;
  position: absolute;
  // top: 2rem;
  top: 3rem;
  right: 50px;
  
  z-index: 1;
  
  -webkit-user-select: none;
  user-select: none;

  @include breakpoint(tablet) {
    top: 2.5rem;
    right: 50px;
 }

  @include breakpoint(mobileonly) {
    top: 2.5rem;
    right: 20px;
 }
}

#menuToggle input[type=checkbox]
{
  color: $white;
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
//    top: -7px;
  left: -5px;
  
  cursor: pointer;
  
  opacity: 0; /* hide this */
  z-index: 2; /* and place it over the hamburger */
  
  -webkit-touch-callout: none;

  top: 0;
  padding: 2rem;
}

#menuToggle span
{
  display: block;
  width: 25px;
  height: 3px;
  margin-bottom: 5px;
  position: relative;
  color: $white;
  
  background: $white;
  border-radius: 3px;
  
  z-index: 1;
  
  transform-origin: 4px 0px;
  
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease;
}

#menuToggle span:first-child
{
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2)
{
  transform-origin: 0% 100%;
}


#menuToggle input[type=checkbox]:checked ~ span
{
  opacity: 1;
  transform: rotate(45deg) translate(0px,2px);
  background: $white;
}


#menuToggle input[type=checkbox]:checked ~ span:nth-last-child(3)
{
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}


#menuToggle input[type=checkbox]:checked ~ span:nth-last-child(2)
{
  opacity: 1;
  transform: rotate(-45deg) translate(0, -1px);
}


div#menu {
    position: absolute;
    width: 105vw;
    height: 100vh;
    margin: -100px 0 0 0;
    padding: 50px;
    padding-top: 125px;
    right: -100px;
    background: $menuBlack;
    color: $white;
    list-style-type: none;
    -webkit-font-smoothing: antialiased;
    transform-origin: 0% 0%;
    transform: translate(100%, 0);
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);

    @include breakpoint(tablet) {
        position: fixed;
        // width: 114vw;
        width: 100vw;
        left: 0;

        // height: 200vh;
        margin: -100px 0 0;
        height: 106vh;
        padding: 8.5rem 2rem 9.5rem 3rem;
    }


    @include breakpoint(mobileonly) {
        position: fixed;
        // width: 114vw;
        left: 0;

        // height: 200vh;
        margin: -100px 0 0;
        height: 106vh;
        padding: 8.5rem 2rem 9.5rem 3rem;
    }
}

#menuToggle input[type=checkbox]:checked~div#menu {
    overflow: scroll;
}

div.hamburger-wrapper {
    font-family: $font-name;
    position: relative;

    // display: grid;

    // grid-template-columns: 1fr 1fr 1fr 1fr;
    // grid-template-columns: 75% 25%;
    // align-content: space-between;
    // grid-gap: 2rem;
    max-width: 1300px;
    margin: 0 auto;

    //   @include breakpoint(tablet) {
    //     grid-template-columns: 85%;
    //  }

    //   @include breakpoint(mobileonly) {
    //     grid-template-columns: 85%;
    //  }

    img.menu-img {
        margin-right: unset;
        height: unset;
        height: unset;
        float: unset;
    }

    h2 {
        line-height: 2.25rem;

        a {
            font-weight: $bold;
            font-size: $Menu-Nav-Size;
            line-height: $Menu-LH;
            letter-spacing: $char-spacing-3;
            transition: color .3s ease;
        }
    }

    a {
        color: $white;
        text-transform: uppercase;

        &:hover {
            color: #e52328;
        }
    }

    li.menu-link:after {
        content: "";
        background-image: url(../svg/port_right-arrow-green.svg);
        height: 15px;
        top: 9px;
        right: 8px;
        background-repeat: no-repeat;
        width: 8px;
        display: inline-block;
        position: absolute;
    }

    ul,
    div.parent-ul {
        position: relative;

        // padding-bottom: 1rem;

        li {
            // padding-right: 1rem;

            // border-bottom: 1px solid white;
            // line-height: 2.5rem;
            // padding-top: .5rem;
            // padding-bottom: .5rem;
            position: relative;

            a {
                font-size: $Button-size;
                line-height: $Button-lh;
                letter-spacing: .12rem;

                // letter-spacing: $char-spacing-3;
                color: $white;

                // position: relative;
                font-weight: $semiBold;

                // padding-right: 55px;
                transition: color .3s ease;

                &:hover {
                    color: #e52328;
                }

                @include breakpoint(tablet) {
                    padding-right: 46px;
                }


                @include breakpoint(tablet) {
                    padding-right: 46px;
                }


                ul {
                    text-indent: 2rem;
                }
            }
        }
    }

    //TODO: USE THIS!
    div.parent-ul {
        // display: grid;

        // grid-template-columns: 33% 33% 33%;
        // grid-template-columns: repeat(3, calc((100% - -30px)/3));

        li {
            a {
                // color: red;

            }

            ul {
                li {
                    &:after {
                        // content: "";
                        // background-image: url(../svg/port_hamburger-dropdown-down.svg);
                        // height: 15px;
                        // top: 9px;
                        // right: 8px;
                        // background-repeat: no-repeat;
                        // width: 8px;
                        // display: inline-block;
                        // position: absolute;

                    }

                    a {
                        color: white;

                        // width: 300px;
                        // display: block;
                        // padding-right: 40px;
                        // border-bottom: 1px solid white;

                    }

                    ul {
                        li {
                            // display: none;

                            &:after {}
                        }
                    }
                }
            }
        }

        // li > ul > li:after {
        //   content: "";
        //   background-image: url(../svg/port_right-arrow-green.svg);
        //   height: 15px;
        //   top: 9px;
        //   right: 8px;
        //   background-repeat: no-repeat;
        //   width: 8px;
        //   display: inline-block;
        //   position: absolute;
        // }

        // ul ul li:after {

        //   content: "";
        //   background-image: url(../svg/port_hamburger-dropdown-down.svg);
        //   height: 15px;
        //   top: 9px;
        //   right: 8px;
        //   background-repeat: no-repeat;
        //   width: 8px;
        //   display: inline-block;
        //   position: absolute;

        // }

        li:after {
            content: "";
            background-image: url(../svg/port_right-arrow-green.svg);
            height: 15px;
            top: 12px;
            margin-right: 0;
            right: 8px;
            background-repeat: no-repeat;
            width: 8px;
            display: inline-block;
            position: absolute;
        }

        // li li:after {
        //   content: "";
        //   background-image: url(../svg/port_hamburger-dropdown-down.svg);
        //   margin-right: -1rem;
        //   height: 15px;
        //   top: 9px;
        //   margin-right: 0;
        //   float: none;
        //   right: 8px;
        //   background-repeat: no-repeat;
        //   width: 8px;
        //   cursor: pointer;
        //   position: absolute;
        // }
        // li ul l:first-child:after {
        //   content: "";
        //   background-image: url(../svg/port_right-arrow-green.svg);
        //   height: 15px;
        //   top: 9px;
        //   margin-right: 0;
        //   right: 8px;
        //   background-repeat: no-repeat;
        //   width: 8px;
        //   display: inline-block;
        //   position: absolute;
        // }

        // li li li:after  {
        //   content: "";
        //   background-image: url(../svg/port_right-arrow-green.svg);
        //   height: 15px;
        //   top: 9px;
        //   margin-right: 0;
        //   right: 8px;
        //   background-repeat: no-repeat;
        //   width: 8px;
        //   display: inline-block;
        //   position: absolute;
        // }

        // ul.parent-ul-subnode ul:not(.parent-ul-subnode) li {
        //   &:after {
        //     content: "";
        //     background-image: url(../svg/port_right-arrow-green.svg);
        //     height: 15px;
        //     top: 9px;
        //     margin-right: 0;
        //     right: 8px;
        //     background-repeat: no-repeat;
        //     width: 8px;
        //     display: inline-block;
        //     position: absolute;
        //   }
        // }

        // ul li a {
        //     &:after {
        //         content: "";
        //         background-image: url(../svg/port_right-arrow-green.svg);
        //         height: 15px;
        //         margin-right: 0;
        //         right: 23px;
        //         bottom: 18px;
        //         background-repeat: no-repeat;
        //         width: 8px;
        //         display: inline-block;
        //         position: absolute;
        //     }
        // }

        // ul.parent-ul-subnode-1 li a {
        //     &:after {
        //         content: "";
        //         background-image: url(../svg/port_right-arrow-green.svg);
        //         height: 15px;

        //         margin-right: 0;
        //         right: 23px;
        //         bottom: 18px;
        //         background-repeat: no-repeat;
        //         width: 8px;
        //         display: inline-block;
        //         position: absolute;
        //     }
        // }

        ul.inner-ul {
            display: none;
        }

        ul li a.dropdown-ul {
            &:after {
                content: "";
                background-image: url(../svg/port_hamburger-dropdown-down.svg);
                margin-right: -.5rem;
                height: 28px;

                // top: 2px;
                background-size: 100% 100%;
                margin-right: 0;
                bottom: 8px;
                z-index: 2;
                pointer-events: none;
                right: 20px;

                // right: 8px;
                width: 28px;
                background-repeat: no-repeat;
                cursor: pointer;
                position: absolute;
            }
        }

        //   li>ul:only-child {

        //     li:after {
        //     content: "";
        //     background-image: url(../svg/port_right-arrow-green.svg);
        //     height: 15px;
        //     top: 9px;
        //     right: 8px;
        //     background-repeat: no-repeat;
        //     width: 8px;
        //     display: inline-block;
        //     position: absolute;
        //   }

        // }

    }

    // div.hamburger-wrapper ul.parent-ul li ul>li:first-child a {
    //   color: red;
    // }
}

// div.hamburger-wrapper ul li a {
//   color: #fff;
//   // padding-right: 0rem;
//   // padding-right: 1.5rem;
// }
// div.hamburger-wrapper ul li {
//   padding-top: 6px;
//   /* padding-top: .5rem;
//   width: 100%;
//   height: auto;
//   display: block;
//   padding-bottom: 6px;
//   /* padding-bottom: .5rem;
// }

.menu-nested {
    padding-top: 2rem;
}

// ul.second-level {
//   display: none;
// }

li.parent {
    border-bottom: 1px solid white;
}

ul.first-level ul li {
    border-bottom: 1px solid white;
}

ul.second-level li a {
    // padding-right: 1.5rem;
}

ul.second-level li {
    padding-left: 1.5rem;
}

li.parent img {
    margin-right: -1rem;

    // margin-right: auto;
    height: 1.89rem;
    float: right;
    cursor: pointer;
}

#menuToggle input[type=checkbox]:checked~div#menu {
    transform: scale(1, 1);
    opacity: 1;
}

.search-container #search-text-4 {
    position: relative;
    right: 0;
    height: 3rem;
    width: 50%;
}

.search-container form {
    margin: 0;
    padding: 0;
}

.search-container #search-submit-4 {
    top: 5px;
    height: 3rem;

    @include breakpoint(tablet) {
      top: 18px;
    height: 3rem;
    }
}

// @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
//     .hamburger-wrapper {
//         display: flex;

//         // flex-wrap: wrap;
//         justify-content: space-between;
//     }

//     div.hamburger-wrapper ul {
//         width: 24%;
//         margin: 1%;
//     }

//     .menu-singles-group {
//         width: 24%;
//         margin: 1%;
//     }

//     li.parent, ul.first-level ul li {
//         overflow-x: hidden;
//         width: 300px;
//         min-width: 250px;
//         max-width: 350px;
//     }
// }

//NEW TODO:

.parent-u-subcontainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    flex: 75%;

    @include breakpoint(tablet) {
        display: block;
    }
}

.parent-ul {
    /* display: flex; */
    flex: 30%;
    margin-right: 3%;

    li {
        border-bottom: 1px solid white;
        padding: .5rem 0;

        a {
            padding-right: 55px;
        }
    }

    @include breakpoint(tablet) {
        margin-top: 2rem;
        margin-right: auto;
    }
}

.parent-ul-container {
    display: flex;

    @include breakpoint(tablet) {
        display: block;
    }


}

.parent-u-subcontainer div.parent-ul:last-child {
    margin-left: auto;

    /* display: block; */
    flex-grow: initial;
}

img.toggle {
    width: 28px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 3;
}

.inner-ul li:last-child {
    border: none;
    padding-bottom: 0;
}

.parent-ul.parent-ul-right {
    // margin-top: 2rem;
}

.inner-ul li {
    padding-left: 1rem;
}

.parent-ul-links {
    // width: 25%;
    margin-top: 2rem;

    @include breakpoint(tablet) {
        width: auto;
        margin-top: 2rem;
    }
}