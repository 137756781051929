.modal-gallery {
    .modal-content {

        @include breakpoint(tablet) {
           height: 500px;
        }
   

        @include breakpoint(mobileonly) {
           height: 500px;
        }
    }    

    
    .modal-bio {
        column-count: unset;
        width: 80%;
        margin: 4rem auto 2rem auto;

        @include breakpoint(tablet) {
            height: auto;
            width: 90%;
         }

        @include breakpoint(mobileonly) {
            height: auto;
            width: 90%;
         }

        p {
            color: $copyGrey;
            font-size: $Body-Size;
            letter-spacing: $Body-Char-Spacing;
            line-height: $Body-LH;
            margin-bottom: -2rem;       
            padding-left: .5rem;
        }
        h3 {
            width: 100%;
            font-size: 1.75rem;
            font-family: Montserrat,sans-serif;
            letter-spacing: .125rem;
            line-height: 2.1875rem;
            color: #626262;
            padding: .5rem;
            float: left;
        }

    } 

    form {
        width: 100%;


    }


    .freeform-row .freeform-column {
        padding: 5px 10px;
    }
    .freeform-row .freeform-column .freeform-input-only-label {
        color: $staticGreen;
        font-size: $Body-Size;
        letter-spacing: $Body-Char-Spacing;
        line-height: $Body-LH;
    }
    // .modal-gallery .freeform-row .freeform-column input[type=checkbox] {
    //     visibility: hidden;
    // }

    .freeform-row .freeform-column .freeform-input-only-label>.freeform-input {
        margin-right: 15px;
    }

    .freeform-row .freeform-column #form-input-email {
        color: $copyGrey;
        text-indent: 5px;
        height: 4rem;
        width: 123%;
        font-size: $Body-Size;
        letter-spacing: $Body-Char-Spacing;
        line-height: $Body-LH;
        padding: 1rem;

        @include breakpoint(tablet) {
            width: 100%;
         }

        @include breakpoint(mobileonly) {
            width: 100%;
         }
    }

    .freeform-row .freeform-column button {
        margin-top: 2rem;
        color: #fff!important;
        text-transform: uppercase;
        background: $staticGreen;
        border: 0;
        padding: 5px 15px 5px 15px;
        width: 75%;
        line-height: 1.875rem;
        font-size: 0.9375rem;
        cursor: pointer;
        height: 4rem;
        border: 1px solid $staticGreen;
        letter-spacing: 0.125rem;
        // margin-right: .5rem;
        // margin-top: 2rem;
        // margin-top: 1rem;
        // margin-bottom: 2rem;
        font-family: $font-name, sans-serif;
        font-weight: 600;
        transition: $hoverGreen-transition;

        @include breakpoint(tablet) {
            width: 100%;
            margin-top: 1.5rem;
         }

        @include breakpoint(mobileonly) {
            width: 100%;
            margin-top: 1.5rem;
         }

            &:after {
                content: " ";
                background-image: url('../svg/chevron_white.svg');
                background-size: cover;
                position: relative;
                display: inline-block;
                top: 3px;
                margin-left: 29px;
                width: 12px;
                height: 18px;

            }

            &:hover {
                background: $hoverGreen;
            }

    }

    input#form-input-email::placeholder {
        color: $staticGreen;
        font-size: $Body-Size;
        line-height: $Body-LH;
        letter-spacing: $char-spacing-2-5;
        // text-transform: capitalize;
        // text-transform: uppercase;
    }    

    input#form-input-terms {
        outline: 0;
        height: auto;
    }
}