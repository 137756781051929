.sf-back-to-top {
    // background-color: #$butt; /* button color */
    // color: $white; /* text/arrow color */ 
    display: none;
    z-index: 4;
    position: fixed;
    right: 0px;
    bottom: 0;
    width: auto;
    /* text-transform: uppercase; */
    height: auto;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

// .fixed_button{
//     position:absolute !important;
//     margin-top:1900px;
//     bottom: auto !important;
// }

a.sf-back-to-top {
    // font-weight: 600;
    // letter-spacing: 2px;
    // font-size: 12px;
    // text-transform: uppercase;
    // text-align: center;
    // line-height: 1.6;
    // padding-left: 2px;
    // padding-top: 4px; 
    
    
    button {
            color: #fff!important;
            border: 0;
            // text-transform: uppercase;
            border: 0;
            background: #C40000;
            padding: 5px 15px;
            width: 215px;
            // line-height: 1.875rem;
            cursor: pointer;
            line-height: 35px;
            font-size: 15px;
            letter-spacing: 2px;
            font-family: Montserrat,sans-serif;
            font-weight: 600;
            transition: $hoverGreen-transition;

            @include breakpoint(tablet) {
                color: #fff!important;
                border: 0;
                background: #C40000;
                padding: 0;
                width: 39px;
                line-height: unset;
                height: 35px;
                
                }


            @include breakpoint(mobileonly) {
                color: #fff!important;
                border: 0;
                background: #C40000;
                padding: 0;
                width: 39px;
                line-height: unset;
                height: 35px;
                
                }

            &:hover {
                background: #e52328;
            }

            img {
                width: 20px;
                height: 14px;
                margin: 0px;
            }
       }
}

// a.sf-back-to-top .arrow:before {
//     content: "\e02d";
//     font-family: 'squarespace-ui-font';
//     font-style: normal;
//     font-weight: 600;
//     font-size: 18px;
//     line-height: 1;
//     speak: none;
//     -webkit-font-smoothing: antialiased;
//     content: "\e02d";
//     text-align: center;
//     display: block;
//     vertical-align: middle;
//     transform: rotate(-90deg);
//     -webkit-transform: rotate(-90deg);
//     -moz-transform: rotate(-90deg);
//     -ms-transform: rotate(-90deg);
//     -o-transform: rotate(-90deg);
//     cursor: pointer;
//     margin-left: -4px;
// }