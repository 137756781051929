figure {
    // @include shadow-low;

    // border: 1px solid $color-border;
    // border-radius: 3px;
    // display: inline-block;
    // margin: 0;
    // padding: 1em;
    // padding-bottom: .7em;

    figcaption {
        font-size: .95em;
        font-weight: 700;
    }
}

iframe {
    // border: 1px solid $color-border;
    // border-radius: 3px;
    width: 100%;
}

/* Ratios
 Returns the ratio for specified dimensions.
*/
@function ratio($width, $height) {
 @return percentage( $height / $width);
}

@mixin generateRatios($width, $height, $prefix: "ratio-") {
    $class-name: $prefix + $width + "x" + $height;
   
    .#{$class-name} {
      padding-bottom: ratio($width, $height);
    }
    // Output example: .ratio-16x9 {}
  }
   
  @include generateRatios(16,9); // 16x9
  @include generateRatios(4,3);  // 4x3