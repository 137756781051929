.block__article {

 


    &-wrapper {
        padding-top: 2rem;
        font-family: $font-name;
        margin: 0 auto;
        width: 90%;


        @include breakpoint(tablet) {
            padding-top: 1rem;
            padding-bottom: 36px;
            padding-bottom: 3rem;
            width: 100%;
    
        }

        @include breakpoint(mobileonly) {
            padding-top: 1rem;
            padding-bottom: 36px;
            padding-bottom: 3rem;
            width: 100%;
    
        }
       
        blockquote {
            font-size: 2rem;
            line-height: 2.5rem;
            color: #0068AB;
            letter-spacing: $char-spacing-2-5;
            width: 85%;
            margin: 0 auto;
            padding-bottom: 2rem;
            text-align: center;
        }

        ol {
            list-style: decimal;
            margin-left: 2rem;
            width: 85%;
        }


        h1 {
            font-size: $Headline-Size;
            line-height: $Headline-LH;
            font-family: $font-name;
            letter-spacing: $char-spacing-3;
            margin: 0 auto;
            color: $copyGrey;
            padding-top: 3rem;
            padding-bottom: 3rem;
            width: 85%;
            @include breakpoint(tablet) {
                width: 90%;
        
            }

            @include breakpoint(mobileonly) {
                width: 90%;
        
            }
        }


        h4.date {
            color: $copyGrey;
            width: 85%;


        }

        .block__text-with-images-container {
            width: 85%;
        }

        .block__text-with-images p:last-of-type {
            margin-bottom: 1.875rem;
        }

    }

    &-image {

        img {
            height: auto;
            width: 85%;
            object-fit: cover;
            margin: 0 auto;
            display: block;

            @include breakpoint(tablet) {
                width:90%
        
            }

            @include breakpoint(mobileonly) {
                width: 90%
        
            }


        }

    }

    &-article-content {
         
    }
}