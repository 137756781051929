.modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    transform: scale(1.1);
    transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
}
.modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 0;
    width: 75%;
    border: 2.8px solid #716258;

    @include breakpoint(tablet) {
        width: 90%;
        height: 450px;
        padding: 1rem;
        overflow-y: initial !important;
    }

    @include breakpoint(mobileonly) {
        width: 90%;
        height: 475px;
        padding: 1rem;
        overflow-y: initial !important;
    }
  

    // @include breakpoint(mobileonly) {
    //     position: absolute;
    //     top: 22%;
    //     left: 23%;
    //     /* right: 50%; */
    //     -webkit-transform: translate(-50%,-50%);
    //     -ms-transform: translate(-50%,-50%);
    //     transform: translate(-50%,-50%);
    //     background-color: #fff;
    //     padding: 0;
    //     margin: 0 auto;
    //     width: 340px;
    //     height: auto;
    //     border: 2.8px solid #0068ab;
    // }
}

.modal-head {
    margin: 3rem 0rem 0 3rem;
    font-family: $font-name, sans-serif;

    @include breakpoint(tablet) {
        margin: 1rem;
        width: 85%;
    }

    @include breakpoint(mobileonly) {
        margin: 1rem;
        width: 85%;
    }
  

        h2 {
            color: $copyGrey;
            font-size: 1.75rem;
            letter-spacing: 0.125rem;
            line-height: 2.1875rem;
            text-transform: uppercase;
            margin: 0;
            padding-bottom: .25rem;

        
        }
        h3 {
            color: $copyGrey;
            font-size: 1rem;
            line-height: 1.875rem;
            letter-spacing: 0.09375rem;
            padding-bottom: .25rem;

        }

        a {
            font-size: 0.875rem;
            line-height: 1.75rem; 
            letter-spacing: 0.15625rem;
            color: #C40000;
            display: block;
            padding-bottom: .25rem;


            @include breakpoint(tablet) {
                font-size: 1rem;
                line-height: 18px;
                line-height: 1.75rem;
            }

            @include breakpoint(mobileonly) {
                font-size: 1rem;
                line-height: 18px;
                line-height: 1.75rem;
            }

        }
}

.close-button {
    position: absolute;
    color: #fff;
    border: 0;
    // top: 1px;
    top: 0px;
    font-size: 40px;
    padding: 5px 7px 12px 8px;
    right: 0px;
    line-height: 20px;
    text-align: center;
    cursor: pointer;
    background-color:  #C40000;
    transition: $hoverGreen-transition;
}

.modal-bio {
    column-count: 2;
    margin: 3rem;
    column-gap: 3rem;
    font-family: $font-name, sans-serif;
    color: $copyGrey;
    font-size: 0.875rem;
    line-height: 1.75rem; 
    letter-spacing: .09375rem;


    @include breakpoint(tablet) {
        column-count: unset;
        column-gap: 0;
        margin: 0;
        height: 290px;
        overflow-y: auto;
        padding: 1rem 0 0 1rem;
        
        
    }

    @include breakpoint(mobileonly) {
        column-count: unset;
        column-gap: 0;
        margin: 0;
        height: 250px;
        overflow-y: auto;
        padding: 1rem 0 0 1rem;
        
        
    }
  
}

.modal-bio p {

    margin-bottom: .5rem;

    @include breakpoint(tablet) {
    
        font-size: 1rem;
        line-height: 21px;
        line-height: 1.75rem;
        width: 95%;
        
    }

    @include breakpoint(mobileonly) {
    
        font-size: 1rem;
        line-height: 21px;
        line-height: 1.75rem;
        width: 95%;
        
    }


}    

.close-button:hover {
    background-color: #e52328;
}
.show-modal {
    z-index: 100;
    opacity: 1;
    visibility: visible;
    transform: scale(1.0);
    transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
}
