.block__staff {

    &-wrapper {
        // margin: 0 auto;
        padding-top: 2rem;
        padding-bottom: 2rem;
        overflow: hidden;
    }

    &-head-shot {
        img {
            object-position: center;
            object-fit: cover;
            width: 100%;
            // max-height: 400px;
            // min-height: 400px;
            // height: 400px;
            @include breakpoint(tablet) {
                width: 100%;
                margin: 0 auto;
                display: block;
            }

            @include breakpoint(mobileonly) {
                width: 85%;
                margin: 0 auto;
                display: block;
            }
            
        }

    }


    &-content {
        font-family: $font-name, sans-serif;
        margin: 0 auto;
        width: 100%;
        padding-top: 2rem;
        padding-bottom: 1rem;

        @include breakpoint(tablet) {
            margin: 0 auto;
            width: 100%;
        }

        @include breakpoint(mobileonly) {
            margin: 0 auto;
            width: 85%;
        }

        h2 {
            color: $copyGrey;
            font-size: 1rem;
            letter-spacing: 0.125rem;
            line-height: 1.5rem;
            text-transform: uppercase;
            margin: 0;
            padding-bottom: .25rem;


            @include breakpoint(tablet) {
                font-size: 1.5rem;
                letter-spacing: .125rem;
                line-height: 2rem;
    
            }

            @include breakpoint(mobileonly) {
                font-size: 1.5rem;
                letter-spacing: .125rem;
                line-height: 2rem;
    
            }
           
        
        }
        h3 {
            color: $copyGrey;
            font-size: 0.85rem;
            line-height: 1.5rem;
            letter-spacing: 0.09375rem;
            padding-bottom: .25rem;

            @include breakpoint(tablet) {
                font-size: 1rem;
                line-height: 18px;
                line-height: 1.75rem;
            }
            @include breakpoint(mobileonly) {
                font-size: 1rem;
                line-height: 18px;
                line-height: 1.75rem;
            }

           
        }

        a {
            font-size: 0.85rem;
            line-height: 1.5rem;
            letter-spacing: .09375rem;
            color: #C40000;
            display: block;
            padding-bottom: .25rem;

            @include breakpoint(tablet) {
                font-size: 1rem;
                line-height: 18px;
                line-height: 1.75rem;
            }
            
            @include breakpoint(mobileonly) {
                font-size: 1rem;
                line-height: 18px;
                line-height: 1.75rem;
            }
        
        }

        .bio {
            font-size: 0.875rem;
            line-height: 1.75rem;
            color: #C40000;
            text-transform: uppercase;
            letter-spacing: 0.15625rem;
            cursor: pointer;

            img {
                height: 15px;
                padding-bottom: 4px;
            }
        }

    }

}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { 

    .block__staff-wrapper {
        width: calc(23%);
        margin: 0 1%;
    }
}    

@supports (-ms-ime-align:auto) {

}
