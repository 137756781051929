.block__promo-box {

    &-item-wrapper {
        position: relative;
    }

    &-item {
        display: grid;
        grid-template-columns: 40% 60%;
        height: 500px;


        @include breakpoint(tablet) {
            grid-template-columns: unset;
         }

        @include breakpoint(mobileonly) {
            grid-template-columns: unset;
         }
        // position: relative;
    }

    &-blank {
        background: #B3D6ED;

        @include breakpoint(tablet) {
            padding-bottom: 20%;
         }
         @include breakpoint(mobileonly) {
            padding-top: 20%;
         }

    }

    &-image {
        background-size: cover;
        position: relative;
        background-position: center center;
    }

    &-image-overlay {
        position:relative;

        @include breakpoint(tablet) {
           padding-top: 80%;
        }

        @include breakpoint(mobileonly) {
            padding-top: 80%;
        }    
    
        &:before {
                position: absolute;
                content:" ";
                top:0;
                left:0;
                width:100%;
                height:100%;
                z-index:0;
                background-color: transparent;
        }     
    }

    &-content {
        position: absolute;

        top: 25%;
        
        bottom: 25%;
        
        right: 40%;
        
        margin: auto;
        
        width: 45%;
        
        display: table;
        
        background: #fff;
        
        padding: 2.5rem;

        @include breakpoint(tablet) {
            position: absolute;
            top: 10%;
            bottom: 0;
            right: 10%;
            left: 10%;
            margin: 0 auto;
            height: auto;
            width: 80%;
            display: table;
            background: #fff;
            padding: 2.5rem;
         }

         @include breakpoint(mobileonly) {
            position: absolute;
            top: 10%;
            bottom: 0;
            right: 10%;
            left: 10%;
            margin: 0 auto;
            width: 80%;
            display: table;
            background: #fff;
            padding: 30px;
            padding: 2.5rem;
         }

        &-table-cell {
            display: table-cell;
            vertical-align: middle;
            height: 100%;
            font-family: $font-name,sans-serif;
            color: $copyGrey;
    
            p {
                font-size: .95rem;
                line-height: 1.575rem;
                letter-spacing: .09375rem;
                margin-bottom: 1.5rem;
    
                &:empty{
                    display: none;
                }
            }
    
            h3 {
                font-size: 1.6rem;
                line-height: 2rem;
                padding-bottom: 1rem;
                letter-spacing: .125rem;
            }
    
            button {
                color: #fff!important;
                text-transform: uppercase;
                background: #C40000;
                border: 0;
                padding: 5px 15px 5px 15px;
                cursor: pointer;
                line-height: 1.875rem;
                font-size: 0.9375rem;
                letter-spacing: 0.125rem;
                font-family: $font-name, sans-serif;
                font-weight: 600;
                transition: $hoverGreen-transition;

                    &:after {
                        content: " ";
                        background-image: url('../svg/chevron_white.svg');
                        background-size: cover;
                        position: relative;
                        display: inline-block;
                        top: 3px;
                        margin-left: 29px;
                        width: 12px;
                        height: 18px;
                    }

                    &:hover {
                        background: #e52328;
                    }
                }
        }
      }

    
}
//Promo Carousel
.block__promo-box-container {
    position: relative;
    max-width: 1300px;
    overflow: hidden;
    margin: 0 auto;

    .carousel-cell {
        width: 100%;
        height: auto;
        margin-right: 10px;
    }
    /* cell number */
    .carousel-cell:before {
        display: block;
        text-align: center;
        line-height: 200px;
        font-size: 80px;
        color: white;
    }

    button.flickity-button {
        display: none;
    }
    /* position dots in gallery */
    .flickity-page-dots {
        top: 93%;
        bottom: 0px;
        position: absolute;
        display: flex;
        right: 25%;

        @include breakpoint(tablet) {
            top: 90%;
            bottom: 0;
            display: flex;
            justify-content: center;
            right: 43vw;
            text-align: center;
         }

       @include breakpoint(mobileonly) {
            top: 90%;
            bottom: 0;
            display: flex;
            justify-content: center;
            right: 43vw;
            text-align: center;
         }
    }
    /* white circles */
    .flickity-page-dots .dot {
            width: 18px;
        cursor: pointer;
        height: 18px;
        margin: 5px;
        opacity: 1;
        background: $white;
        border: 2px solid $white;
    }
    /* fill-in selected dot */
    .flickity-page-dots .dot.is-selected {
        background: #C40000;
    }


}

.block__promo-box-container-wrapper {

    overflow: hidden;

}

//   @media only screen and (min-width:1024px){ // media query 
//     @-moz-document url-prefix() { // target FF
//         .block__promo-box-image-overlay {
//             height: 500px;
//         }
//     }
// }

//IE11
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { 

    .block__promo-box-image-overlay {
        width: 50%;
    }

    .block__promo-box-blank {
        width: 50%;
    }

    .block__promo-box-item {
        display: flex;
    }
}
