.google-maps {
    max-width:1300px;
    margin: 0 auto;
    position: relative;
    padding-bottom: 50%;
    height: 0;
    overflow: hidden;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
    }


}

.gis {
    .embed-container {
            max-height: 700px;
            height: 700px;
            padding: 0;

            @include breakpoint(tablet) {
                max-height: 500px;
                height: 500px;
                padding: 0;
              }
        
              @include breakpoint(mobile) {
                max-height: 300px;
                height: 300px;
                padding: 0;
              }
    }


}

#map {
    max-width: 1300px;
    margin: 0 auto;
    display: block;
    // padding-left: .75rem;
    // padding-right: .75rem

    @include breakpoint(tablet) {
        width: 90%;;
      }
    @include breakpoint(mobileonly) {
        width: 90%;;
      }
   

    div {
        // margin: .75rem;
        
    }
}