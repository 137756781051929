@mixin clearfix {
    &::after {
        clear: both;
        content: ' ';
        display: table;
    }
}

@mixin shadow-float {
    box-shadow: 0 12px 15px 0 rgba(0, 0, 0, .22),
                0 17px 20px 0 rgba(0, 0, 0, .12);
}

@mixin shadow-high {
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .15),
                0 4px 14px 0 rgba(0, 0, 0, .12);
}

@mixin shadow-mid {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .15),
                0 2px 10px 0 rgba(0, 0, 0, .12);
}

@mixin shadow-low {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14),
                0 1px 5px 0 rgba(0, 0, 0, .12),
                0 3px 1px -2px rgba(0, 0, 0, .2);
}
