.block__recent-news {
    padding-top: 2rem;
    @include breakpoint(tablet) {
        padding: 0;
        padding-bottom: 3rem;
    }  

    @include breakpoint(mobileonly) {
        padding: 0;
        padding-bottom: 3rem;
    }  

    button {
        margin: 40px auto 2rem 2rem;
        display: inline-block;
        color: #fff!important;
        /* text-transform: uppercase; */
        border: 0;
        background:#C40000;
        padding: 12px 16px;
        line-height: 3.125rem;
        font-size: 2.5rem;
        cursor: pointer;
        letter-spacing: 0.1875rem;
        font-family: Montserrat,sans-serif;
        font-weight: 600;
        width: auto;
        transition: $hoverGreen-transition;

        @include breakpoint(tablet) {
            width: 100%;
            margin: 0;
        }  

         @include breakpoint(mobileonly) {
            width: 100%;
            margin: 0;
        }  

            &:after {
                content: " ";
                background-image: url(../svg/chevron_white.svg);
                background-size: cover;
                position: relative;
                display: inline-block;
                top: 4px;
                margin-left: 22px;
                width: 18px;
                height: 29px;

            }

            &:hover {
                background: #e52328;
            }
    }

    &-wrapper {

        display: grid;
        grid-gap: 1%;
        grid-template-columns: 30% 30% 30%;
        -ms-flex-pack: distribute;
        justify-content: center;
        // padding-top: 3rem;
        // padding-bottom: 3rem;
        

        @include breakpoint(tablet) {
            display: block;
            padding-bottom: 3rem;
          } 
        @include breakpoint(mobileonly) {
          display: block;
        }  
    }

    &-item {
        padding: 1rem;
        font-family: $font-name, sans-serif;
        color: $copyGrey;
        display: grid;
        grid-template-rows: 1fr;

        @include breakpoint(tablet) {
            width: 90%;
            margin: 0 auto;
            padding-top: 2rem;
            padding-bottom: 0rem;
        }  

        @include breakpoint(mobileonly) {
            width: 90%;
            margin: 0 auto;
            padding-top: 2rem;
            padding-bottom: 0rem;
        }  

        h3 {
            font-size: 1.75rem;
            letter-spacing: 0.125rem;
            line-height: 2.1875rem;
            padding: 1rem 0;
        }

        h4.date {
            font-size: 1rem;
            letter-spacing: 0.15625rem;
            line-height: 1.875rem;
            font-weight: 300;
            font-style: italic;
            padding: 0rem 0rem 1rem 0rem;
        }
        p {
            width: 100%;
            margin: 0 auto;
            // font-size: 1rem;
            // line-height: 1.875rem;
            // max-height: 75px;
            // min-height: 75px;
            font-size: 1rem;
            line-height: 1.875rem;
            // margin-bottom: 1.875rem;
            letter-spacing: 0.09375rem;
        }

        button {
            // margin: 40px auto 2rem auto;
            margin: 2rem 0;
            display: inline-block;
            color: #fff!important;
            text-transform: uppercase;
            background: #C40000;
            border: 0;
            cursor: pointer;
            padding: 5px 25px 5px 25px;
            line-height: 1.875rem;
            font-size: 0.9375rem;
            letter-spacing: 0.125rem;
            font-family: $font-name, sans-serif;
            font-weight: 600;
            width: auto;
            transition: $hoverGreen-transition;


            @include breakpoint(tablet) {
                margin: 2rem 0 0 0;
                width: 100%;
            }  


            @include breakpoint(mobileonly) {
                    margin: 2rem 0 0 0;
                    width: 100%;
                }  

                &:after {
                    content: " ";
                    background-image: url('../svg/chevron_white.svg');
                    background-size: cover;
                    position: relative;
                    display: inline-block;
                    top: 3px;
                    margin-left: 29px;
                    width: 12px;
                    height: 18px;

                }

                &:hover {
                    background: #e52328;
                }
        }
    }

}

//IE11
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { 
    .block__recent-news-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .block__recent-news-wrapper :after{
        content:'';
        flex-grow: 1;
      }

      .block__recent-news-item  {
          width: 30%;
      }
}