.block__treated-text{

    color: white;
    // padding: 3rem;
    text-align: left;
    // font-size: 4.375rem;
    // line-height: 85px;
    font-size: 3.125rem;
    line-height: 4.0625rem;
    width: 85%;
    font-family: $font-name, sans-serif;
    letter-spacing: 0.125rem; 

    @include breakpoint(tablet) {
       width: 100%;
       text-align: center;
       font-size: 2.5rem;
       line-height: 3rem;
    }

    @include breakpoint(mobileonly) {
        width: 100%;
        text-align: center;
        font-size: 2.5rem;
        line-height: 3rem;
     }

    a {
        background: #C40000;
        color: white;
    }
    &-container {
        background: $darkBlue;
        width: 100%;
        padding: 5rem 3rem 5rem 3rem;
        @include breakpoint(tablet) {
            padding: 2rem 2rem;
        }

        @include breakpoint(mobileonly) {
            padding: 2rem 2rem;
        }
       
    
    }
}