 /* Typographic styles and vertical rhythm pulled from http:www.gridlover.net/app/ */

 :root {
    /* These values are the minimum and maximum viewport sizes to apply the font scaling */
    --minwidth: 600;
    --maxwidth: 2048;
    /* These values represent the range of fon-tsize to apply */
    /* These values effect the base font-size, headings and other elements will scale proportionally */
    --minfont: 13;
    --maxfont: 17;
  }
  /* @custom-media --minmax (min-width: 600px) and (max-width: 1800px); */
  /* @custom-media --max (min-width: 1800px); */
 
  :root {
    font-size: calc(var(--minfont) * 1px);
  }
 
  @media (min-width: 600px) and (max-width: 2048px) {
     :root {
      font-size: calc((var(--minfont) * 1px) + (var(--maxfont) - var(--minfont)) * ((100vw - calc(var(--minwidth) * 1px)) / (var(--maxwidth) - var(--minwidth))));
    }
  }
 
  @media (min-width: 2048px) {
     :root {
      font-size: calc(var(--maxfont) * 1px);
    }
  }
 
  body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: inherit;
    font-weight: 400;
    font-style: normal;
    line-height: 1.5rem;
    letter-spacing: .01rem;
    text-size-adjust: 100% !important;
  }
 
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 400;
  }
 
  h1,
  .h1 {
    margin-top: 0;
    margin-bottom: 1.5rem;
    font-size: 4.25rem;
    line-height: 4.5rem;
  }
 
  h2,
  .h2 {
    margin-top: 0;
    margin-bottom: 1.5rem;
    font-size: 2.625rem;
    line-height: 3rem;
  }
 
  h3,
  .h3 {
    margin-top: 0rem;
    margin-bottom: 0rem;
    font-size: 1.625rem;
    line-height: 3rem;
  }
 
  h4,
  .h4 {
    margin-top: 0;
    margin-bottom: 0rem;
    font-size: 1rem;
    line-height: 1.5rem;
  }
 
  h5,
  .h5 {
    margin-top: 0;
    margin-bottom: 0rem;
    font-size: 1rem;
    line-height: 1.5rem;
  }
 
  p,
  ul,
  ol,
  pre,
  table,
  blockquote {
    margin-top: 0rem;
    // margin-bottom: 1.5rem;
  }
 
  p {
    /* max-width: 65ch; */
  }
 
  ul ul,
  ol ol,
  ul ol,
  ol ul {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
 
  li {
    font-size: inherit;
    line-height: 1.5rem;
  }
  /* Let's make sure all's aligned */
 
  hr,
  .hr {
    margin: -1px 0;
    border: 1px solid;
  }
 
  a,
  b,
  i,
  strong,
  em,
  small,
  code {
    /* line-height: 0; */
  }
 
  sub,
  sup {
    position: relative;
    /* line-height: 0; */
    vertical-align: baseline;
  }
 
  sup {
    top: -.5em;
  }
 
  sub {
    bottom: -.25em;
  }
 
  strong,
  b {
    font-weight: 700 !important;
  }
 
  i,
  em {
    font-style: italic !important;
  }
  /* EOF */
 