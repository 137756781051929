.block__director {

    &-wrapper {
        margin: 0 auto;
        padding-top: 2rem;
        padding-bottom: 2rem;


    }

    &-head-shot {

        img {
            object-position: center;
            object-fit: cover;
            width: 100%;
            max-height: 400px;
            min-height: 400px;
            height: 400px;

            @include breakpoint(tablet) {
                object-fit: cover;
                margin: 0 auto;
                display: block;
                width: 100%;
                height: auto;
                max-height: unset;
                min-height: unset;
             }
             @include breakpoint(mobileonly) {
                object-fit: cover;
                margin: 0 auto;
                display: block;
                height: auto;
                width: 85%;
                max-height: unset;
                min-height: unset;
             }


        }
    }

    &-content {
        font-family: $font-name, sans-serif;
        margin: 0 auto;
        width: 85%;
        padding-top: 2rem;
        // padding-bottom: 1rem;


            @include breakpoint(tablet) {
                width: 100%;
            }

            @include breakpoint(mobileonly) {
                width: 85%;
            }


        h2 {
            color: $copyGrey;
            font-size: 1.75rem;
            letter-spacing: 0.125rem;
            line-height: 2.1875rem;
            text-transform: uppercase;
            margin: 0;
            padding-bottom: .25rem;

            @include breakpoint(tablet) {
                font-size: 1.5rem;
            }
            @include breakpoint(mobileonly) {
                font-size: 1.5rem;
            }
          

        
        }
        h3 {
            color: $copyGrey;
            font-size: 1rem;
            line-height: 1.875rem;
            letter-spacing: 0.09375rem;
            padding-bottom: .25rem;

        }

        a {
            font-size: 0.875rem;
            line-height: 1.75rem; 
            letter-spacing: 0.15625rem;
            color: #C40000;
            display: block;
            padding-bottom: .25rem;

        }

        .bio {
            font-size: 0.875rem;
            line-height: 1.75rem;
            color: #C40000;
            text-transform: uppercase;
            letter-spacing: 0.15625rem;
            cursor: pointer;

            img {
                height: 15px;
                padding-bottom: 4px;
            }
        }

    }
}

//IE11
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { 

    .block__director-wrapper {
        width: calc(31%);
        margin: 0 1%;
    }
}

@supports (-ms-ime-align:auto) {

}
