.contact__form {

    max-width: 1300px;
    margin: 0 auto;
    margin-top: 2rem;
    
    

    &-header {
        width: 60%;
        margin: 0 auto;

        @include breakpoint(tablet) {
            width: 90%;
          }

        @include breakpoint(mobileonly) {
            width: 90%;
          }
        

        h3 {
            font-size: $Subhead-Size;
            font-family: $font-name;
            letter-spacing: $char-spacing-2;
            line-height: $Subhead-LH;
            color: $copyGrey;
            padding: .5rem;
            float: left;
            text-transform: uppercase;
        }

        .form-pdf  {

            float: left;
            padding: 1rem;
            width: auto;

              a:after {

                content: " ";
                background-image: url('../svg/port_download-green.svg');
                background-size: cover;
                position: relative;
                display: inline-block;
                top: 2px;
                margin-left: 16px;
                width: 18px;
                height: 15px;

            }
        
        a {

            text-transform: uppercase;
            font-weight: $semiBold;
            color: #C40000;
            letter-spacing: $char-spacing-2;
            font-size: $Button-size;
            line-height: $Button-lh;
        }

            img {

            }
        }
    }

}