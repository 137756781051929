section.staff-listings {

    hr {
        margin: 2rem 0;
        color: #C40000;
        border: none;
        height: 1px;
        background-color: #C40000;

        @include breakpoint(tablet) {
            margin: 0 0 1rem 0;
        }

        @include breakpoint(mobileonly) {
            margin: 0 0 1rem 0;
        }

    }

    .director-posts {
        display: grid;
        grid-template-columns: 30% 30% 30%;
        justify-content: center;
        // grid-gap: 2%;
        grid-gap: 2rem;
        margin-top: 2rem;

        @include breakpoint(tablet) {
            grid-template-columns: 45% 45%;
        }

        @include breakpoint(mobileonly) {
            grid-template-columns: 100%;
        }

        .director-posts:after{
            content:'';
            flex-grow: 1;
          }

    }

    .staff-posts {
        display: grid;
        grid-template-columns: 21% 21% 21% 21%;
        justify-content: center;
        grid-gap: 2rem;
        margin-top: 2rem;

        @include breakpoint(tablet) {
            grid-template-columns: 45% 45%;
        }
        
        @include breakpoint(mobileonly) {
            grid-template-columns: 100%;
        }
    }

    
    .board-posts {
        display: grid;
        grid-template-columns: 45% 45%;
        justify-content: center;
        // grid-gap: 2%;
        grid-gap: 2rem;
        padding-bottom: 3rem;

        @include breakpoint(tablet) {
            grid-template-columns: 100%;
        }

        @include breakpoint(mobileonly) {
            grid-template-columns: 100%;
        }
      
    }

}

// IE11
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { 
    .staff-listings .director-posts {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .director-posts:after{
        content:'';
        flex-grow: 1;
      }
    .staff-listings .staff-posts {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .staff-posts:after{
        content:'';
        flex-grow: 1;
      }
    .staff-listings .board-posts {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .board-posts:after{
        content:'';
        flex-grow: 1;
      }
}

@supports (-ms-ime-align:auto) {
  

}
